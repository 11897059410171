<template>
  <!-- begin:: Footer -->
  <div class="footer py-4 d-flex flex-lg-column" id="kt_footer">
    <!--begin::Container-->
    <div
      class="d-flex flex-column flex-md-row align-items-center justify-content-between"
      v-bind:class="{ 'container-fluid': widthFluid, container: !widthFluid }"
    >
      <!--begin::Copyright-->
      <div class="text-dark order-2 order-md-1">
        <span class="text-muted font-weight-bold mr-2">{{ year }}©</span>
        <a
          :href="$t('LAYOUT.FOOTER.SITE.URL')"
          target="_blank"
          class="text-dark-75 text-hover-primary"
          >{{ $t("LAYOUT.FOOTER.SITE.TITLE") }}</a
        >
      </div>
      <!--end::Copyright-->
      <!--begin::Nav-->
      <div class="nav nav-dark order-1 order-md-2">
        <!-- <a
          :href="$t('LAYOUT.FOOTER.ABOUT.URL')"
          target="_blank"
          class="nav-link pr-3 pl-0"
          >{{ $t("LAYOUT.FOOTER.ABOUT.TITLE") }}</a
        >
        <a
          :href="$t('LAYOUT.FOOTER.TEAM.URL')"
          target="_blank"
          class="nav-link px-3"
          >{{ $t("LAYOUT.FOOTER.TEAM.TITLE") }}</a
        >
        <a
          :href="$t('LAYOUT.FOOTER.CONTACT.URL')"
          target="_blank"
          class="nav-link pl-3 pr-0"
          >{{ $t("LAYOUT.FOOTER.CONTACT.TITLE") }}</a
        > -->
        <p>
          Desenvolvido com muita dedicação e
          <i class="icon fas fa-heart text-danger"></i> especialmente para sua
          empresa / website.
        </p>
      </div>
      <!--end::Nav-->
    </div>
    <!--end::Container-->
  </div>
  <!-- end:: Footer -->
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "KTFooter",
  computed: {
    ...mapGetters(["layoutConfig"]),

    /**
     * Check if footer container is fluid
     */
    widthFluid() {
      return this.layoutConfig("footer.width") === "fluid";
    },
    year() {
      return new Date().getFullYear();
    }
  }
};
</script>
