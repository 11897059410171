<template>
  <!-- begin:: Header Topbar -->
  <div class="topbar">
    <!--begin: Search -->
    <!-- <DropdownCreate></DropdownCreate> -->

    <!--begin: Quick panel toggle -->
    <!-- <KTQuickPanel></KTQuickPanel> -->
    <!--end: Quick panel toggle -->

    <!--begin: Quick panel toggle -->
    <!-- <KTQuickActions></KTQuickActions> -->
    <!--end: Quick panel toggle -->
    <div class="plan">
      <router-link
        to="/plans"
        class="btn btn-sm btn-light font-weight-bold mr-2"
        id="kt_dashboard_daterangepicker"
        data-toggle="tooltip"
        title=""
        data-placement="left"
        data-original-title="Select dashboard daterange"
      >
        <span
          class="text-muted font-size-base font-weight-bold mr-2"
          id="kt_dashboard_daterangepicker_title"
          >Plano:</span
        >
        <span
          class="text-primary font-size-base font-weight-bolder"
          id="kt_dashboard_daterangepicker_date"
          >{{ currentUser.plan.name }}</span
        >
      </router-link>
    </div>
    <!--begin: User Bar -->
    <KTQuickUser></KTQuickUser>
    <!--end: User Bar -->

    <!-- <KTQuickNotifications></KTQuickNotifications> -->
  </div>
  <!-- end:: Header Topbar -->
</template>

<style lang="scss" scoped>
.plan {
  display: flex;
  align-items: center;
  justify-content: center;
}
.topbar {
  .dropdown-toggle {
    padding: 0;
    &:hover {
      text-decoration: none;
    }

    &.dropdown-toggle-no-caret {
      &:after {
        content: none;
      }
    }
  }

  .dropdown-menu {
    margin: 0;
    padding: 0;
    outline: none;
    .b-dropdown-text {
      padding: 0;
    }
  }
}
</style>

<script>
// import DropdownCreate from "@/view/layout/extras/dropdown/DropdownCreate.vue";
import KTQuickUser from "@/view/layout/extras/offcanvas/QuickUser.vue";
// import KTQuickPanel from "@/view/layout/extras/offcanvas/QuickPanel.vue";
// import KTQuickActions from "@/view/layout/extras/offcanvas/QuickActions.vue";
// import KTQuickNotifications from "@/view/layout/extras/offcanvas/QuickNotifications.vue";
import { mapGetters } from "vuex";
export default {
  name: "KTTopbar",
  data() {
    return {};
  },
  components: {
    // DropdownCreate,
    KTQuickUser
    // KTQuickPanel,
    // KTQuickActions,
    // KTQuickNotifications
  },
  methods: {},
  computed: {
    ...mapGetters(["currentUser"]),
    getLanguageFlag() {
      return this.onLanguageChanged();
    }
  }
};
</script>
