<template>
  <ul class="menu-nav">
    <router-link
      v-for="(item, index) in list"
      :key="index"
      :to="item.link"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="checkActive(item.entity)"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <!-- <i class="menu-icon flaticon2-architecture-and-city"></i> -->
          <inline-svg
            class="menu-icon"
            :src="$t(`ENTITY.${item.entity}.ICON`)"
          />
          <span class="menu-text">{{ $t(`ENTITY.${item.entity}.TITLE`) }}</span>
        </a>
      </li>
    </router-link>
  </ul>
</template>

<script>
export default {
  name: "KTMenu",
  methods: {
    hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    },
    checkActive(entity) {
      return entity === this.routeActive ? "menu-item-active" : "";
    }
  },
  computed: {
    list() {
      return this.$t("MENU.LIST");
    },
    routeActive() {
      return this.$route.meta.entity || "DASHBOARD";
    }
  }
};
</script>
